import React from 'react'

export const CloseSvgIcon = (props) => (
  <svg {...props} width="100px" height="100px" viewBox="0 0 10 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>close circle</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-37.000000, -47.000000)" fillRule="nonzero">
            <g id="xmark.circle.fill" transform="translate(37.000000, 47.000000)">
                <path d="M4.99600395,10.3220882 C5.68112632,10.3220882 6.32497483,10.187786 6.92754948,9.91918167 C7.53012414,9.65057729 8.06150709,9.27751551 8.52169835,8.79999633 C8.98188115,8.32247279 9.34301262,7.77247111 9.60509274,7.14999129 C9.86717286,6.52751148 9.99821292,5.8645293 9.99821292,5.16104477 C9.99821292,4.45756024 9.86717286,3.7945737 9.60509274,3.17208515 C9.34301262,2.54960534 8.98084572,1.99960802 8.51859203,1.52209321 C8.0563468,1.04456966 7.52393686,0.671508756 6.92136221,0.402910493 C6.31878755,0.134303498 5.67493904,0 4.98981667,0 C4.3088276,0 3.66704574,0.134303498 3.06447109,0.402910493 C2.46189643,0.671508756 1.93154469,1.04456966 1.47341586,1.52209321 C1.01529548,1.99960802 0.655196076,2.54960534 0.393117646,3.17208515 C0.131039215,3.7945737 -1.1261074e-16,4.45756024 -1.1261074e-16,5.16104477 C-1.1261074e-16,5.8645293 0.131039215,6.52751148 0.393117646,7.14999129 C0.655196076,7.77247111 1.01632669,8.32247279 1.4765095,8.79999633 C1.93670075,9.27751551 2.46808371,9.65057729 3.07065836,9.91918167 C3.67323302,10.187786 4.31501488,10.3220882 4.99600395,10.3220882 Z" id="Path" fill="#40474F"></path>
                <path d="M3.36781239,7.32267096 C3.24399927,7.32267096 3.13772521,7.27577257 3.04899022,7.1819758 C2.96025523,7.08817902 2.91588774,6.97519417 2.91588774,6.84302122 C2.91588774,6.71510939 2.95922402,6.60639 3.04589659,6.51686307 L4.35215966,5.16743643 L3.04589659,3.81802289 C2.95922402,3.72848723 2.91588774,3.61976784 2.91588774,3.49186474 C2.91588774,3.3596918 2.96025523,3.24883749 3.04899022,3.15930183 C3.13772521,3.06976616 3.24399927,3.02499833 3.36781239,3.02499833 C3.48750911,3.02499833 3.59068953,3.06763561 3.67735364,3.15291017 L4.99600395,4.50871537 L6.32704148,3.1465054 C6.41371405,3.05696974 6.51689446,3.01220191 6.63658273,3.01220191 C6.76452916,3.01220191 6.87286986,3.05803502 6.96160485,3.14970124 C7.05033984,3.24137619 7.09470734,3.35116521 7.09470734,3.47906832 C7.09470734,3.61124126 7.04930863,3.72209557 6.95851122,3.81163123 L5.64604819,5.16743643 L6.95231126,6.51045831 C7.04310868,6.59573287 7.08850739,6.70658717 7.08850739,6.84302122 C7.08850739,6.97519417 7.04310868,7.08817902 6.95231126,7.1819758 C6.86151385,7.27577257 6.75214193,7.32267096 6.62419551,7.32267096 C6.49212423,7.32267096 6.38481896,7.27790313 6.3022797,7.18836746 L4.99600395,5.83254916 L3.69592815,7.18836746 C3.60925558,7.27790313 3.49988366,7.32267096 3.36781239,7.32267096 Z" id="Path" fill="#FFFFFF"></path>
            </g>
        </g>
    </g>
</svg>
)
