import React from "react"
import ReactPlayer from "react-player"
import styles from "../../styles/_components/video/video.module.scss"

const Video = props => {

  return (
    <div className={styles.videoContainer}>
      <div className={styles.wrapperPlayer}>
        <ReactPlayer
          url={props.url}
          controls={true}
          playing={true}
          autoPlay={true}
          muted={true}
          className={styles.videoPlayer}
          height="100%"
          width="100%"
        />
      </div>
    </div>
  )
}

export default Video
