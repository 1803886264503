import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { node, func } from 'prop-types'
import styles from '../../styles/_components/modal/modal.module.scss';
import { ButtonClose } from './buttonClose'

const Modal = ({ children, closeModalHandler }) => {
  const [modalRoot, setModalRoot] = useState(null)

  const [counter, setCounter] = useState(0);
  const [showCloseButton, setShowCloseButton] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(prevCounter => prevCounter + 1);
    }, 1000);

    if (counter === 11) {
      clearInterval(interval);
      setShowCloseButton(true);
    }

    return () => {
      clearInterval(interval);
    };
  }, [counter]);
  

  useEffect(() => {
    document.querySelector('body').classList.add('body-hidden')
    const modal = document.getElementById('modal-root')
    setModalRoot(modal)

    return () => {
      document.querySelector('body').classList.remove('body-hidden')
    }
  }, [])

  return modalRoot
    ? ReactDOM.createPortal(
      <div className={styles.modal}>
        <div className={styles.overlay} />
        { showCloseButton && <ButtonClose onClick={closeModalHandler} /> }
        <div className={styles.content}>
          {children}
        </div>
      </div>,
      modalRoot
      )
    : null
}

Modal.propTypes = {
  children: node,
  closeModalHandler: func,
}

Modal.defaultProps = {
  children: null,
  closeModalHandler: null,
}

export default Modal
