import React from "react";
import styles from '../../styles/_components/modal/buttonClose.module.scss';
import { CloseSvgIcon } from "../svg/close_svg_icon"

export const ButtonClose = ({onClick}) => {
  return(
    <div className={`${styles.aCloseButton}`} onClick={onClick}>
        <CloseSvgIcon className={`${styles.closeSvg}`}/>
    </div>
  )
}
